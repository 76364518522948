import React from 'react';
import { Box } from 'rebass';
import { TextContainer, H4, TextBody1 } from '../ui';
import { phone } from '../layouts/media';
import styled, { css } from 'styled-components';

function createMarkup(text) {
  return { __html: text };
}

export const Question = ({ question }) => (
  <TextContainer id={question.nr}>
    <QuestionContainer my={5}>
      <Box mb={[3, 4]}>
        <H4>{question.q}</H4>
      </Box>
      <TextBody1>
        <div dangerouslySetInnerHTML={createMarkup(question.a)} />
      </TextBody1>
    </QuestionContainer>
  </TextContainer>
);

const QuestionContainer = styled(Box)`
  ${phone(css`
    &:first-child {
      margin-top: 32px;
    }
  `)};
`;
