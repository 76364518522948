import React from 'react';
import styled from 'styled-components';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Flex } from 'rebass';
import { H6 } from '../ui';

const url = 'https://gymlovers.pt';

const SocialShare = ({ slug, name }) => {
  return (
    <Box mt={5} mb={[5, 6]}>
      <H6 textAlign="center">Partilha esta entrevista</H6>
      <Social>
        <SocialIcons>
          <Link
            rel="noreferrer"
            href={`https://twitter.com/intent/tweet?source=webclient&url=${url}${slug}&text=Grande%20entrevista%20com%20${name}%20no%20@gymloverspt%20❤️`}
            target="_blank"
          >
            <FontAwesomeIcon icon={['fab', 'twitter']} />
          </Link>
        </SocialIcons>
        <SocialIcons>
          <Link
            rel="noreferrer"
            href={`https://www.facebook.com/sharer.php?u=${url}${slug}`}
            target="_blank"
          >
            <FontAwesomeIcon icon={['fab', 'facebook-f']} />
          </Link>
        </SocialIcons>
      </Social>
    </Box>
  );
};

const Link = styled(OutboundLink)`
  color: inherit;
  text-decoration: none;
`;

const Social = styled(Flex)`
  justify-content: space-around;
  margin: auto;
  width: 88px;

  margin-top: 20px;
`;

const SocialIcons = styled(Box)`
  display: flex;
  justify-content: space-around;
  font-size: 28px;
  color: ${props => props.theme.colors.blackish};

  &:hover {
    color: ${props => props.theme.colors.blue};
  }
`;

export default SocialShare;
