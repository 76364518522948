import React from 'react';
import styled, { css } from 'styled-components';
import { Box } from 'rebass';
import { TextContainer, H4 } from '../ui';
import { phone, laptop } from '../layouts/media';

export const ChooseOne = ({ questions }) => (
  <ChooseOneWrapper id="choose">
    <Box pt={[4, 5]} pb={[4, 0]}>
      <TextContainer>
        <H4>Se só pudesses escolher um qual seria?</H4>
      </TextContainer>
    </Box>
    <Box pb={[4, 5]}>
      <TextContainer>
        <Grid>
          {questions.map((question, index) => {
            return (
              <GridItem key={index}>
                <Title>{question.q}</Title>
                <Response>{question.a}</Response>
              </GridItem>
            );
          })}
        </Grid>
      </TextContainer>
    </Box>
  </ChooseOneWrapper>
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 202px);
  grid-column-gap: 112px;
  grid-row-gap: 78px;

  margin: 70px 0 30px 0;
  justify-content: center;


  ${laptop(css`
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 32px;
  `)}; };

  ${phone(css`
    grid-template-columns: 1fr;
    grid-row-gap: 42px;

    margin: 0;
  `)}; };
`;

const GridItem = styled.div`
  width: 100%;
`;

const Title = styled.div`
  font-family: ${props => props.theme.fonts.family};
  font-weight: 600;
  font-size: 17px;

  margin-bottom: 16px;
`;

const Response = styled.div`
  font-size: 18px;
`;

const ChooseOneWrapper = styled.div`
  margin: auto;

  background: linear-gradient(
    180deg,
    rgba(133, 177, 220, 0.12) 0%,
    rgba(66, 104, 141, 0.0716022) 72%,
    rgba(133, 177, 220, 0) 100%
  );

  ${phone(css`
    background: linear-gradient(
      180deg,
      rgba(133, 177, 220, 0.12) 0%,
      rgba(66, 104, 141, 0.0716022) 92.85%,
      rgba(133, 177, 220, 0) 100%
    );
  `)}; };
`;
