import React from 'react';
import styled, { css } from 'styled-components';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { phone, laptop } from '../layouts/media';
import { Box } from 'rebass';

const ImagePair = ({ images, onClickImage }) => {
  return (
    images[1] !== undefined && (
      <Grid>
        <Image image={getImage(images[0])} onClick={() => onClickImage(images[0]) } />
        <Image image={getImage(images[1])} onClick={() => onClickImage(images[1]) } />
      </Grid>
    )
  );
}

const Image = styled(GatsbyImage)`
  width: 100%;
  height: 0px;
  padding-bottom: 120%;
  border-radius: 24px;
  cursor: pointer;

    & > picture {
    & > img {
      object-position: center 10% !important;
      border-radius: 24px;

      ${laptop(css`
        object-position: center 18% !important;
      `)};
    }
`;

const Grid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 32px;
  grid-row-gap: 32px;

  max-width: 1080px;

  margin: auto;
  padding: 0 32px;

  ${phone(css`
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-row-gap: 32px;

    padding: 0 16px;
  `)}; };
`;


export default ImagePair;
