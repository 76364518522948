import React from 'react';
import { Box } from 'rebass';
import { phone } from '../layouts/media';
import styled, { css } from 'styled-components';
import { GatsbyImage } from "gatsby-plugin-image";

export const FoodImages = ({ images }) => (
  <Grid>
    <Image image={images[0].childImageSharp.gatsbyImageData} />
    <Image image={images[1].childImageSharp.gatsbyImageData} />
  </Grid>
);

const Image = styled(GatsbyImage)`
  background-color: ${props => props.theme.colors.gray[0]};
  margin-top: -32px;
  height: 300px;
  
   & > picture {
    & > img {
      object-position: 42% 90% !important;
    }
  }

  ${phone(css`
    padding-bottom: 58%;
    &:last-child {
      margin-top: 0;
    }
  `)}; };
`;

const Grid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;

  max-width: 720px;

  margin: auto;

  ${phone(css`
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-row-gap: 16px;

    padding: 0 16px;
  `)}; };
`;
