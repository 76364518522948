import React from 'react';
import theme from '../layouts/theme';
import { Box } from 'rebass';
import { Grid, Container, H3, InterviewCard } from '../ui';
import _ from 'lodash';
import { getPostsForScreenSize } from '../components/Utilities';

const RelatedInterviews = ({ allPosts, postId }) => {
  const otherPosts = removeCurrentPost(allPosts, postId);
  const randomPosts = getRandomPosts(otherPosts);
  const posts = getPostsForScreenSize(randomPosts);

  return (
    <Box bg={theme.colors.blackish}>
      <Container py={[4]}>
        <Box my={[3, 4, 5]}>
          <H3 color="white" textAlign="center">
            Mais entrevistas{' '}
            <span
              role="img"
              alt="point down emoji"
              aria-label="point down emoji"
            >
              👇
            </span>
          </H3>
        </Box>
        <Grid>
          {posts.map(({ node: post }) => (
            <InterviewCard key={post.id} post={post} color={'white'} />
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

const removeCurrentPost = (allPosts, postId) => {
  let postIndex = _.findIndex(allPosts, function(post) {
    return post.node.id === postId;
  });
  allPosts.splice(postIndex, 1);
  return allPosts;
};

const getRandomPosts = posts => {
  const maxRange = posts.length;
  let numberOne = Math.floor(Math.random() * maxRange);
  let numberTwo = Math.floor(Math.random() * maxRange);
  let numberThree = Math.floor(Math.random() * maxRange);
  let randomPosts = [];
  // run this loop until numberOne is different than numberThree
  do {
    numberOne = Math.floor(Math.random() * maxRange);
  } while (numberOne === numberThree);
  // run this loop until numberTwo is different than numberThree and numberOne
  do {
    numberTwo = Math.floor(Math.random() * maxRange);
  } while (numberTwo === numberThree || numberTwo === numberOne);

  randomPosts.push(posts[numberOne]);
  randomPosts.push(posts[numberTwo]);
  randomPosts.push(posts[numberThree]);
  return randomPosts;
};

export default RelatedInterviews;
