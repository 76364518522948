import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import { getSrc } from "gatsby-plugin-image";

import { randomNumber } from "../utils/helpers";

import Layout from "../components/layout";
import HeadOfInterview from "../components/HeadOfInterview";
import QandA from "../components/QandA";
import SocialShare from "../components/SocialShare";
import RelatedInterviews from "../components/RelatedInterviews";

export const interviewPostQuery = graphql`
  query($id: String!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "interview-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 180)
          id
          fields {
            slug
          }
          frontmatter {
            title
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            templateKey
            description
            color
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 180)
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        featuredImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        description
        color
        bio {
          weight
          height
          age
          city
        }
        foodImages {
          childImageSharp {
            gatsbyImageData(width: 450, layout: CONSTRAINED)
          }
        }
        images {
          childImageSharp {
            gatsbyImageData(width: 800, layout: CONSTRAINED)
          }
        }
        chooseOne {
          q
          a
        }
        questions {
          q
          a
          nr
        }
      }
    }
  }
`;

const InterviewPost = ({ data, location }) => {
  const {
    markdownRemark: post,
    allMarkdownRemark: { edges: posts },
  } = data;
  const url = "https://gymlovers.pt";
  const featuredImage = post.frontmatter.featuredImage;
  return (
    <Layout location={location} interview={posts[randomNumber(posts.length)]}>
      <InterviewPostTemplate
        content={post.html}
        description={post.frontmatter.description}
        helmet={
          <Helmet>
            <title>{`${post.frontmatter.title} | Entrevista`}</title>
            <meta name="description" content={post.excerpt} />

            <meta
              itemprop="name"
              content={`${post.frontmatter.title} | Entrevista`}
            />
            <meta itemprop="description" content={post.excerpt} />
            <meta itemprop="image" content={`${url}${getSrc(featuredImage)}`} />

            <meta property="og:url" content={`${url}${post.fields.slug}/`} />
            <meta property="og:type" content="website" />
            <meta
              property="og:title"
              content={`${post.frontmatter.title} | Entrevista`}
            />
            <meta property="og:description" content={post.excerpt} />
            <meta
              property="og:image"
              content={`${url}${getSrc(featuredImage)}`}
            />

            <meta name="twitter:card" content="summary_large_image" />
            <meta
              name="twitter:title"
              content={`${post.frontmatter.title} | Entrevista`}
            />
            <meta name="twitter:description" content={post.excerpt} />
            <meta
              name="twitter:image"
              content={`${url}${getSrc(featuredImage)}`}
            />
          </Helmet>
        }
        chooseOne={post.frontmatter.chooseOne}
        questions={post.frontmatter.questions}
        title={post.frontmatter.title}
        foodImages={post.frontmatter.foodImages}
        images={post.frontmatter.images}
        bio={post.frontmatter.bio}
        featuredImage={featuredImage}
        slug={post.fields.slug}
        color={post.frontmatter.color}
        posts={posts}
        postId={post.id}
        date={post.frontmatter.date}
      />
    </Layout>
  );
};

const InterviewPostTemplate = ({
  description,
  questions,
  chooseOne,
  foodImages,
  images,
  featuredImage,
  bio,
  title,
  slug,
  color,
  helmet,
  posts,
  postId,
  date,
}) => {
  return (
    <section>
      {helmet || ""}
      <HeadOfInterview
        title={title}
        description={description}
        image={featuredImage}
        bio={bio}
        color={color}
        date={date}
      />
      <QandA
        questions={questions}
        foodImages={foodImages}
        images={images}
        chooseOne={chooseOne}
      />
      <SocialShare slug={slug} name={title} />
      <RelatedInterviews allPosts={posts} postId={postId} />
    </section>
  );
};

export default InterviewPost;
