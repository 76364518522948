import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Box, Flex } from 'rebass';
import { H2, H5, TextBody1, MiniText } from '../ui';
import { phone, laptop } from '../layouts/media';
import { fadeInUp, fadeInLeft } from 'react-animations';
import { GatsbyImage } from "gatsby-plugin-image";
import { getFormatedDate } from '../components/Utilities';

const HeadOfInterview = ({ image, title, description, bio, color, date }) => {
  return (
    <Grid>
      <Image image={image.childImageSharp.gatsbyImageData} backgroundColor={color} />
      <Center mx={[3, 4]}>
        <AnimatedTitle>
          <Flex alignItems={['baseline']} flexDirection={['column']}>
            <Box mb={['-6px', '-12px', '-22px']}>
              <MiniText>{getFormatedDate(date)}</MiniText>
            </Box>
            <H2>{title}</H2>
          </Flex>
        </AnimatedTitle>
        <AnimatedText width={1} mb={[3, 4]} mt={[1, 2]}>
          <TextBody1 dangerouslySetInnerHTML={{ __html: description }}/>
        </AnimatedText>
        <Bio my={[3, 4, 2]}>
          <Box mx={[2, 3]} my={[2, 3]}>
            Natural de
            <H5>{bio.city}</H5>
          </Box>
          <Box mx={[2, 3]} my={[2, 3]}>
            Idade
            <H5>{bio.age}</H5>
          </Box>
          <Box mx={[2, 3]} my={[2, 3]}>
            Altura
            <H5>{bio.height} m</H5>
          </Box>
          <Box mx={[2, 3]} my={[2, 3]}>
            Peso
            <H5>{bio.weight} kg</H5>
          </Box>
        </Bio>
      </Center>
    </Grid>
  );
};

const fadeUpAnimation = keyframes`${fadeInUp}`;
const fadeLeftAnimation = keyframes`${fadeInLeft}`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.3fr;

  ${laptop(
    css`
      grid-template-columns: 1fr 1.5fr;
    `
  )};

  ${phone(css`
    grid-template-columns: 1fr;
    grid-gap: 8px;
  `)}; };
`;

const Image = styled(GatsbyImage)`
  height: 80vh;
  animation: 1.5s ${fadeLeftAnimation};
  border-radius: 0 24px 24px 0;

  & > picture {
    & > img {
      object-position: 28% 12% !important;
      border-radius: 0 24px 24px 0;

      ${laptop(css`
        object-position: 58% 12% !important;
        border-radius: 0 24px 24px 0;
      `)};
    }
  }

  ${laptop(css`
    border-radius: 0 24px 24px 0;
    height: 560px;
  `)};

  ${phone(css`
    border-radius: 0;
    height: 440px;
  `)};
`;

const Center = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  max-width: 640px;

  ${laptop(css`
    max-width: 448px;
  `)};
`;

const AnimatedTitle = styled(Box)`
  animation: 2s ${fadeUpAnimation};

  ${phone(css`
    animation: 1s ${fadeUpAnimation};
  `)};
`;

const AnimatedText = styled(Box)`
  animation: 2s ${fadeUpAnimation};

  ${phone(css`
    animation: 1.5s ${fadeUpAnimation};
  `)};
`;

const Bio = styled(Box)`
  display: flex;
  justify-content: space-between;

  width: 100%;
  padding: 12px;
  border-radius: 12px;

  background-color: ${props => props.theme.colors.lightBlue};

  animation: 2s ${fadeUpAnimation};

  ${laptop(css`
    padding: 0;
  `)};

  ${phone(css`
    border-radius: 6px;
    animation: 1.5s ${fadeUpAnimation};
    padding: 12px;
  `)};
`;

export default HeadOfInterview;
