import React from 'react';
import { Box } from 'rebass';
import { Question } from './Question';
import { ChooseOne } from './ChooseOne';
import { FoodImages } from './FoodImages';
import { getSrc } from "gatsby-plugin-image";
import { Lightbox } from "react-modal-image";
import ImagePair from './ImagePair';
import { isMobile } from './Utilities';


const QandA = ({ questions, images, chooseOne, foodImages }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [currentImage, setCurrentImage] = React.useState("");

  const firstSetOfQuestions = questions.slice(0, 2);
  const secondSetOfQuestions = questions.slice(2, 4);
  const thirdSetOfQuestions = questions.slice(4, 5);
  const fourthSetOfQuestions = questions.slice(5, 6);
  const fifthSetOfQuestions = questions.slice(6, 9);
  const lastQuestion = questions.slice(9, 10);

  const setLightbox = (image) => {
    setCurrentImage(image)
    setIsOpen(true)
  }

  return (
    <Box>
      {isOpen && !isMobile() && (
        <Lightbox
          hideDownload
          hideZoom
          medium={`${process.env.GATSBY_HOST}${getSrc(currentImage)}`}
          onClose={() => setIsOpen(false)}
        />
      )}
      {firstSetOfQuestions.map((question) => {
        return <Question key={question.nr} question={question} />;
      })}
      <ImagePair images={[images[0], images[1]]} onClickImage={setLightbox} />
      {secondSetOfQuestions.map((question) => {
        return <Question key={question.nr} question={question} />;
      })}
      <ImagePair images={[images[2], images[3]]} onClickImage={setLightbox} />
      {thirdSetOfQuestions.map((question) => {
        return <Question key={question.nr} question={question} />;
      })}
      {foodImages[1] && <FoodImages images={foodImages} />}
      {fourthSetOfQuestions.map((question) => {
        return <Question key={question.nr} question={question} />;
      })}
      <ImagePair images={[images[6], images[7]]} onClickImage={setLightbox} />
      {fifthSetOfQuestions.map((question) => {
        return <Question key={question.nr} question={question} />;
      })}
      <Box mb={[4, 5]}>
        <ImagePair images={[images[4], images[5]]} onClickImage={setLightbox} />
      </Box>
      <ChooseOne questions={chooseOne} />
      {lastQuestion.map((question) => {
        return <Question key={question.nr} question={question} />;
      })}
    </Box>
  );
};

export default QandA;
